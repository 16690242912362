<template>
  <v-list-item-group>
    <v-list-item
      v-for="({ title, icon, to }, i) in items"
      :key="i"
      :to="to"
      active-class="primary"
      exact
    >
      <v-list-item-icon>
        <v-icon v-text="icon" />
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title v-text="title" />
      </v-list-item-content>
    </v-list-item>
  </v-list-item-group>
</template>

<script>
import { i18n } from "@/plugins/i18n";

export default {
  name: "ListItemGroupMenu",
  data() {
    return {
      items: [
        {
          title: i18n.t("cartItem"),
          icon: "mdi-cart",
          to: { name: "ShoppingCart" },
        },
        {
          title: i18n.t("menuItem"),
          icon: "mdi-silverware",
          to: { name: "Menu" },
        },
        {
          title: i18n.t("reservationItem"),
          icon: "mdi-calendar-check",
          to: { name: "Bookings" },
        },
      ],
    };
  },
};
</script>
